import React, { FC } from 'react';
import styled from 'styled-components';
import { colorPalette } from '@shared/theme';
import { LinkedInIcon, TwitterIcon, MailIcon, FacebookIcon } from './icons';

const BarStyled = styled.div`
	position: fixed;
	right: 0;
	top: calc(50% - 100px);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 200px;
	width: 50px;
	background-color: ${colorPalette.socialBarBackground};
	z-index: 999;

	@media screen and (max-width: 992px) {
		display: none;
	}
`;

const BarItemStyled = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	transition: background-color 0.22s linear;

	&:hover {
		background-color: ${colorPalette.black};
	}
`;

export const SocialBar: FC = () => {
	return (
		<BarStyled>
			<BarItemStyled
				href="https://twitter.com/intent/tweet?url=&text=Ervaar%20het%20klimaat%20van%202050%20in%20uw%20omgeving%3A%20https%3A%2F%2Fklimaat.esri.nl%20%7C%20See%20What%20Others%20Can%E2%80%99t%20%23esri%20%23gis%20%23locationintelligence%20%23klimaatverandering"
				target="_blank"
			>
				<TwitterIcon />
			</BarItemStyled>
			<BarItemStyled
				href="mailto:?body=Ervaar het klimaat van 2050 in uw omgeving: https://klimaat.esri.nl"
				target="_blank"
			>
				<MailIcon />
			</BarItemStyled>
			<BarItemStyled
				href="https://www.linkedin.com/shareArticle?mini=true&url=https://klimaat.esri.nl&title=Ervaar%20het%20klimaat%20van%202050%20in%20uw%20omgeving%3A%20https%3A%2F%2Fklimaat.esri.nl%20%7C%20See%20What%20Others%20Can%E2%80%99t%20%23esri%20%23gis%20%23locationintelligence%20%23klimaatverandering"
				target="_blank"
			>
				<LinkedInIcon />
			</BarItemStyled>
			<BarItemStyled
				href="https://www.facebook.com/sharer.php?u=https://klimaat.esri.nl&quote=Ervaar%20het%20klimaat%20van%202050%20in%20uw%20omgeving%3A%20https%3A%2F%2Fklimaat.esri.nl%20%7C%20See%20What%20Others%20Can%E2%80%99t%20%23esri%20%23gis%20%23locationintelligence%20%23klimaatverandering"
				target="_blank"
			>
				<FacebookIcon />
			</BarItemStyled>
		</BarStyled>
	);
};
