import React, { FC } from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import { colorPalette } from '@shared/theme';
import {
	Scroll,
	FacebookIcon,
	TwitterIcon,
	MailIcon,
	LinkedInIcon,
} from '@components/ui';

const WrapperStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	margin-top: 131px;

	@media screen and (max-width: 992px) {
		margin-top: 50px;
	}

	@media screen and (min-width: 992px) {
		margin-bottom: 30px;
	}
`;

const ContentWrapperStyled = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 400px;
`;

const TitleStyled = styled.h3`
	font-size: 19px;
	line-height: 1.47;
	font-weight: bold;
	color: ${colorPalette.dimGray};
`;

const DescriptionStyled = styled.p`
	padding: 5px 15px 0;
	line-height: 1.5;
	font-size: 16px;
	font-weight: 500;
	color: ${colorPalette.lightGray};
`;

const SocialLinkStyled = styled.a`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: ${colorPalette.dimGray};
	margin: 40px 5px 20px;
	cursor: pointer;
	transition: background-color 0.22s linear;

	&:hover {
		background-color: ${colorPalette.black};
	}

	@media screen and (max-width: 992px) {
		margin: 40px 5px 50px;
	}
`;

const ScrollStyled = styled.div`
	max-width: 100px;

	@media screen and (max-width: 992px) {
		display: none;
	}
`;

export const SocialInfo: FC = () => {
	return (
		<WrapperStyled>
			<ContentWrapperStyled>
				<TitleStyled>Vond u het interessant?</TitleStyled>
				<DescriptionStyled>
					Maak klimaat bespreekbaar en deel de impact van het klimaat met uw
					vrienden en familie
				</DescriptionStyled>
				<Flex justifyContent="center">
					<SocialLinkStyled
						href="https://www.facebook.com/sharer.php?u=https://klimaat.esri.nl&quote=Ervaar%20het%20klimaat%20van%202050%20in%20uw%20omgeving%3A%20https%3A%2F%2Fklimaat.esri.nl%20%7C%20See%20What%20Others%20Can%E2%80%99t%20%23esri%20%23gis%20%23locationintelligence%20%23klimaatverandering"
						target="_blank"
					>
						<FacebookIcon />
					</SocialLinkStyled>
					<SocialLinkStyled
						href="https://twitter.com/intent/tweet?url=&text=Ervaar%20het%20klimaat%20van%202050%20in%20uw%20omgeving%3A%20https%3A%2F%2Fklimaat.esri.nl%20%7C%20See%20What%20Others%20Can%E2%80%99t%20%23esri%20%23gis%20%23locationintelligence%20%23klimaatverandering"
						target="_blank"
					>
						<TwitterIcon />
					</SocialLinkStyled>
					<SocialLinkStyled
						href="mailto:?body=Ervaar het klimaat van 2050 in uw omgeving: https://klimaat.esri.nl"
						target="_blank"
					>
						<MailIcon />
					</SocialLinkStyled>
					<SocialLinkStyled
						href="https://www.linkedin.com/shareArticle?mini=true&url=https://klimaat.esri.nl&title=Ervaar%20het%20klimaat%20van%202050%20in%20uw%20omgeving%3A%20https%3A%2F%2Fklimaat.esri.nl%20%7C%20See%20What%20Others%20Can%E2%80%99t%20%23esri%20%23gis%20%23locationintelligence%20%23klimaatverandering"
						target="_blank"
					>
						<LinkedInIcon />
					</SocialLinkStyled>
				</Flex>

				<ScrollStyled>
					<Scroll to="#veelgesteldevragen" />
				</ScrollStyled>
			</ContentWrapperStyled>
		</WrapperStyled>
	);
};
