import React, { FC } from 'react';
import styled from 'styled-components';
import { colorPalette } from '@shared/theme';

export type CardDirection = 'left' | 'right';
type CardSize = 'md' | 'sm';

interface IImageCardProps {
	image: string;
	size?: CardSize;
	hasPadding?: boolean;
	direction?: CardDirection;
	cardTitle?: string;
	alt?: string;
}

interface IStyledProps {
	direction: CardDirection;
	size?: CardSize;
	hasPadding?: boolean;
}

const WrapperStyled = styled.div`
	max-width: 570px;
	${(props: IStyledProps) => {
		return props.direction === 'right' ? 'order: 1' : 'order: 2';
	}};
	position: relative;

	@media screen and (max-width: 1265px) {
		max-width: 500px;
	}

	@media screen and (max-width: 1135px) {
		max-width: 400px;
	}
`;

const ImageStyled = styled.img`
	display: block;
	width: 100%;
	height: 650px;
	object-fit: cover;
	margin: 0;

	@media screen and (max-width: 1135px) {
		height: 500px;
	}
`;

const CardWrapperStyled = styled.div`
	position: relative;
	display: flex;
	text-align: center;
	flex-direction: column;
	align-items: center;
	padding: ${(props: IStyledProps) =>
		props.hasPadding ? '60px 35px' : '0 !important'} ;
	box-sizing: border-box;
	width: 490px;
	top: ${(props: IStyledProps) => (props.size === 'md' ? '-240px' : '-120px')};
	background-color: ${colorPalette.concrete};

	${(props: IStyledProps) =>
		props.direction === 'left' ? 'left: -40px;' : 'right: -40px; float: right;'}
	height: ${(props: IStyledProps) => (props.size === 'md' ? '600px' : '300px')};

	@media screen and (max-width: 1265px) {
		padding: ${(props: IStyledProps) =>
			props.hasPadding ? '40px 25px' : '0 !important'} ;
		width: 440px;

		${(props: IStyledProps) =>
			props.direction === 'left'
				? 'left: -30px;'
				: 'right: -30px; float: right;'}
		height: ${(props: IStyledProps) => (props.size === 'md' ? '500px' : '300px')};
	}

	@media screen and (max-width: 1135px) {
		top: -140px;
		width: 350px;
		padding: ${(props: IStyledProps) =>
			props.hasPadding ? '25px 15px' : '0 !important'} ;

		${(props: IStyledProps) =>
			props.direction === 'left'
				? 'left: -20px;'
				: 'right: -20px; float: right;'}
		height: ${(props: IStyledProps) => (props.size === 'md' ? '400px' : '300px')};
	}

	@media screen and (max-width: 1040px) {
		top: -140px;
		width: 343px;
	}
`;

const CardTitleStyled = styled.p`
	font-size: 19px;
	font-weight: 500;
	line-height: 1.47;
	max-width: 370px;
	padding: 0 0 35px 0 !important;
	margin: 0 !important;

	@media screen and (max-width: 1265px) {
		padding-bottom: 40px;
	}

	@media screen and (max-width: 1135px) {
		font-size: 16px;
	}
`;

export const ImageCard: FC<IImageCardProps> = ({
	children,
	image,
	cardTitle,
	direction = 'left',
	size = 'md',
	hasPadding = true,
	alt,
}) => {
	return (
		<WrapperStyled direction={direction}>
			<ImageStyled src={image} alt={alt} />
			<CardWrapperStyled
				size={size}
				direction={direction}
				hasPadding={hasPadding}
			>
				{cardTitle ? (
					<CardTitleStyled dangerouslySetInnerHTML={{ __html: cardTitle }} />
				) : null}
				{children}
			</CardWrapperStyled>
		</WrapperStyled>
	);
};
